import React from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const Navbar = () => {
    const navigate = useNavigate();
    const Logout = async()=>{
        try {
            await axios.delete('https://webapi-portalnb.nawabhakti.sch.id/logout');
            navigate("/");
        } catch (error) {
            console.log(error);
        }

    }
  return (
    <nav className="navbar is-light" role="navigation" aria-label="main navigation">
        <div className="container">
      <div className="navbar-brand">
        <a className="navbar-item" href="https://bulma.io">
        <p><b>PortalNB</b></p>
        </a>
    
        <a role="button" href='/' className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
    
      <div id="navbarBasicExample" className="navbar-menu">
        <div className="navbar-start">
          <a className="navbar-item" href='/'>
            Home
          </a>
        </div>
    
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <button onClick={Logout} className="button is-light" href='/'>
                Log Out
              </button>
            </div>
          </div>
        </div>
      </div>
      </div>
    </nav>
  )
}

export default Navbar
